import App from '@/App.vue'
import ApiService from '@/common/api_services'
import i18n from '@/common/i18n'
import { VUE_APP_WS_SENTRY } from '@/conf'
// import { VUE_APP_WS_NOTIFICATIONS } from '@/conf'
import router from '@/router'
import store from '@/store'
// import { SOCKET_MUTATIONS } from '@/store/actions/notification'
import * as Sentry from '@sentry/vue'
import AOS from 'aos'
import Vue from 'vue'
// import VueNativeSock from 'vue-native-websocket'

import '@/common/components/global_components'
import '@/common/filters'
import '@/plugins/vee-validate.js'

import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'hooper/dist/hooper.css'
import 'aos/dist/aos.css'
import './assets/css/ar.css'
import './assets/css/en.css'

if (process.env.NODE_ENV != 'development') {
    Sentry.init({
        Vue: Vue,
        dsn: VUE_APP_WS_SENTRY,
    })
}

router.beforeEach((to, from, next) => {
    document.title = to.name
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    let language = to.params.lang
    if (!language) {
        language = 'en'
    }
    i18n.locale = language

    if (to.params.lang != 'ar' && to.params.lang != 'en') {
        next({
            path: `/en${to.path}`,
        })
    } else if (to.meta.isAuthenticated) {
        if (!localStorage.getItem('token')) {
            next({
                path: `/${i18n.locale}/login?prev=${
                    to.name === 'profile-settings' ? 'profile' : to.name
                }&queryParams=${JSON.stringify(to.params)}`,
            })
        } else {
            next()
        }
    } else if (!to.meta.isAuthenticated) {
        if (localStorage.getItem('token')) {
            next({ path: `${i18n.locale}/profile` })
        } else {
            next()
        }
    } else {
        next()
    }
})

ApiService.init()
Vue.config.productionTip = false

// var token = ''

// if (localStorage.getItem('token')) {
//     token = JSON.parse(localStorage.getItem('token'))
// }
// Vue.use(VueNativeSock, `${VUE_APP_WS_NOTIFICATIONS}?token=${token}`, {
//     store: store,
//     mutations: SOCKET_MUTATIONS,
// })
new Vue({
    router,
    store,
    created() {
        AOS.init()
    },
    i18n,
    render: h => h(App),
}).$mount('#app')
